import './App.css';
import NavRoutes from './components/Navroutes';

function App() {
  return (
    <NavRoutes />
  );
}

export default App;
