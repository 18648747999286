import React, { useEffect } from 'react'
import ItemSectionHeader from '../itemSectionHeader'
import ItemSection from '../itemSection'
import './drink.css'
import logo from '../../images/nav_logo.png'
import Container from '../../wrappers/Container'
import ItemList from '../../shared/components/ItemList.component'
import { beers, classiCocktails, signatureCocktails, wines, shots, nonAlcoholicDrinks, draftBeers, champagnes } from '../../data/drink.data'
import Item from '../../shared/components/Item.component'
import { useParams } from 'react-router-dom'
import everestImageOne from '../../images/everestImageOne.jpg'
import everestImageTwo from '../../images/everestImageTwo.jpg'
import everestImageThree from '../../images/everestImageThree.jpg'
import everestImageFour from '../../images/everestImageFour.jpg'
import everestImageFive from '../../images/everestImageFive.jpg'
import everestImageSix from '../../images/everestImageSix.jpg'
import everestImageSeven from '../../images/everestImageSeven.jpg'
import everestImageEight from '../../images/everestImageEight.jpg'
import arrow from '../../images/arrow.png'

const Drink = () => {
  let { id: sectionId } = useParams()
  useEffect(() => {
    if (sectionId) {
      const ele = document.getElementById(sectionId)
      if (ele) {
        ele.scrollIntoView({ behavior: "smooth", vertical: 'start' })
      } else {
        const ele = document.getElementById('drink-container')
        ele.scrollIntoView({ behavior: "smooth", vertical: 'start' })
      }
    }
  }, [sectionId])

  return (
    <Container id="drink-container" container={{ class: "item-list-container" }}>
      <div className='item-list-logo'>
        <img src={logo} alt="8848-logo" className='logo-8848-logo' />
      </div>
      <div className='disclaimer'>
        <p>
          <small>Alcoholic beverages are intended for customers of legal drinking age. Please drink responsibly.</small>
        </p>
        <p>
          <small>Birthday Special! See our staff.</small>
        </p>
      </div>
      <div className='item-container-wrapper'>
      <ItemSection bg={everestImageOne} id="sectionOne">
        <>
          <ItemSectionHeader title="SIGNATURE" subTitle="COCKTAILS" id="singature-cocktail-header" />
          <ItemList id="singature-cocktail">
            {signatureCocktails.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
      </ItemSection>

      <ItemSection bg={everestImageTwo} id="sectionTwo">
        <>
          <ItemSectionHeader title="CLASSIC" subTitle="COCKTAILS" id="classic-cocktail-header" alternate={true} />
          <ItemList id="classic-cocktail" >
            {classiCocktails.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
      </ItemSection>

      <ItemSection bg={everestImageThree} id="sectionThree">
        <>
          <ItemSectionHeader title="SHOTS" id="shots-header" />
          <ItemList id="shots" >
            {shots.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
      </ItemSection>

      <ItemSection bg={everestImageFour} id="sectionFour">
        <>
            <ItemSectionHeader title="Wine" id="wine-header" alternate={true} />
            <div className='disclaimer'>
              <h6>House Wine by glass (served chilled)</h6>
              <h6>Red - 10 | White - 10</h6>
            </div>
            <div className='sub-section'>
              <h6 className='sub-section-title'>BOTTLES</h6>
              <img src={arrow} alt='down arrow' />
            </div>
          <ItemList id="wine" >
            {wines.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
      </ItemSection>

      <ItemSection bg={everestImageFive} id="sectionFive">
        <>
          <ItemSectionHeader title="BEER" id="beer-header" />
          <ItemList id="beer">
            {beers.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
      </ItemSection>

      <ItemSection bg={everestImageSix} id="sectionSix">
        <>
          <ItemSectionHeader title="DRAFT BEER" alternate={true} id="draft-beer-header" />
          <ItemList id="draft-beer" >
            {draftBeers.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
        </ItemSection>
        
        <ItemSection bg={everestImageEight} id="sectionEight">
        <>
            <ItemSectionHeader title="CHAMPAGNE" id="champagne-header" alternate={false} />
          <ItemList id="champagne" >
            {champagnes.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
        </ItemSection>
      <ItemSection bg={everestImageSeven} id="sectionSeven">
        <>
          <ItemSectionHeader title="NON-ALCOHOLIC" subTitle="DRINK" id="non-alcoholic-header" alternate={true}/>
          <ItemList id="non-alcoholic">
            {nonAlcoholicDrinks.map(item => (
              <Item key={item.id} item={item} />
            ))}
          </ItemList>
        </>
        </ItemSection>
        <div className='disclaimer'>
        <p>
          <small>We cannot guarantee the absence of allergens or cross-contamination in our drink ingredients. Please inform our staff of any allergies or dietary restrictions.</small>
        </p>
      </div>
     </div>
      
    </Container>
  )
}

export default Drink
