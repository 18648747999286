import React, { useEffect } from 'react'
import './itemSection.css'

const ItemSection = ({ bg, id, children }) => {
  useEffect(() => {
    if(!id) return
    const ele = document.querySelector(`#${id}`)
    if (ele && bg) {
      ele.style.backgroundImage = `url(${bg})`
    }
  }, [bg, id])
  return (<div className='itemSection' id={id}>{children}</div>)
}

export default ItemSection
