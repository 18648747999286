import { v4 as uuidv4 } from 'uuid';

export const classiCocktails = [
  {
    id: uuidv4(),
    name: 'Long Island Iced Tea',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'Paloma',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'whiskey sour',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'Mojito',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'French 75',
    price: '14'
  },
  {  
    id: uuidv4(),
    name: 'Old Fashioned',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'MARGARITA',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'Negroni',
    price: '14'
  },
    {
    id: uuidv4(),
    name: 'Cosmopolitan',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'Manhattan',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'DAIQUIRI',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'PIÑA COLADA',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'GIN AND TONIC',
    price: '14'
  },
  {
    id: uuidv4(),
    name: 'Moscow mule',
    price: '14'
  }]


export const signatureCocktails = [
  {
    id: uuidv4(),
    name: 'Potala Sunrise',
    description: 'Casamigos Tequila, orange juice, cranberry juice, grenadine',
    price: '18'
  },
  {
    id: uuidv4(),
    name: 'Yeti Whisper',
    description: 'Malibu rum, blue curaçao, pineapple juice, soda water',
    price: '18'
  },
  {
    id: uuidv4(),
    name: 'Himalayan Rani',
    description: 'Empress Gin, rose water or rose syrup, elderflower liqueur, soda water',
    price: '18'
  },
  {
    id: uuidv4(),
    name: 'Summit Splash',
    description: 'DIPLOMATICO Rum, passion fruit juice, pineapple juice, soda water',
    price: '18'
  },
  {
    id: uuidv4(),
    name: 'Titaura Twist',
    description: 'Grey goose Vodka, tamarind syrup, cranberry juice, lemon juice',
    price: '18'
  },
  {
    id: uuidv4(),
    name: 'Amritjhal',
    description: 'Hennessy , honey syrup, apple juice, ginger ale',
    price: '18'
  },
  {
    id: uuidv4(),
    name: '8848',
    description: 'Mitchers Bourbon, peach schnapps, lemon juice, club soda',
    price: '18'
  },
]

export const beers = [
  {
    id: uuidv4(),
    name: 'CORONA',
    price: '7/35'
  },
  {
    id: uuidv4(),
    name: 'HEINEKEN',
    price: '7/35'
  },
  {
    id: uuidv4(),
    name: 'MODELO',
    price: '7/35'
  },
  {
    id: uuidv4(),
    name: 'STELLA',
    price: '7/35'
  },
  {
    id: uuidv4(),
    name: 'LAGUNITAS IPA',
    price: '7/35'
  },
  {
    id: uuidv4(),
    name: 'Barasingha',
    price: '8/42'
  },
]

export const draftBeers = [
  {
    id: uuidv4(),
    name: '8848 Ale ',
    price: '8'
  },
  {
    id: uuidv4(),
    name: 'Yuengling lager',
    price: '8'
  },
]

export const wines = [
  {
    id: uuidv4(),
    name: 'CABERNET SAUVIGNON',
    description: 'Bold, with rich dark berry flavors and structured tannins.',
    secondDesc: 'red',
    price: '60'
  },
  {
    id: uuidv4(),
    name: 'MERLOT',
    description: 'Medium-bodied, with flavors of ripe plum, blackberry, and a smooth finish',
    secondDesc: 'red',
    price: '60'
  },
  {
    id: uuidv4(),
    name: 'SAUVIGNON BLANC',
    description: 'Crisp and refreshing, with notes of citrus, green apple, and tropical fruits.',
    secondDesc: 'white',
    price: '60'
  },
]

export const champagnes = [
  {
    id: uuidv4(),
    name: 'HOUSE',
    price: '60'
  },
  {
    id: uuidv4(),
    name: 'MOET',
    price: '200'
  },
]

export const shots = [
  {
    id: uuidv4(),
    name: 'GREEN TEA',
    price: '10'
  },
  {
    id: uuidv4(),
    name: 'Lemon DROP',
    price: '10'
  },
  {
    id: uuidv4(),
    name: 'jagerbomb',
    price: '10'
  },
]

export const nonAlcoholicDrinks = [
  {
    id: uuidv4(),
    name: 'coke',
    price: '3'
  },
  {
    id: uuidv4(),
    name: 'Red Bull',
    price: '6'
  },
  {
    id: uuidv4(),
    name: 'Club Soda',
    price: '3'
  },
  {
    id: uuidv4(),
    name: 'Sprite',
    price: '3'
  },
  {
    id: uuidv4(),
    name: 'Seltzer',
    price: '3'
  },
  {
    id: uuidv4(),
    name: 'Cranberry',
    price: '4'
  },
  {
    id: uuidv4(),
    name: 'Orange juice',
    price: '4'
  },
  {
    id: uuidv4(),
    name: 'Apple juice',
    price: '4'
  },
  {
    id: uuidv4(),
    name: 'Water',
    price: '2'
  },
]
