import React, { useEffect } from 'react'
import './food.css'

const Food = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [])
  return (
    <div className='food-container'>
      <h1 className='food-coming-soon'>COMING SOON<span>...</span></h1>
    </div>
  )
}

export default Food
